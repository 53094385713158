import Plyr from 'plyr';

const players = {};

Array.from(document.querySelectorAll('.player')).forEach(video => {
    players[video.id] = new Plyr(video, {
        fullscreen: {
            iosNative: true
        }
    });
    let pathname = $(video).attr("data-pagename");

    players[video.id].on('ready', (event) => {
        $('.player').removeClass('d-none');
    });

    players[video.id].on('playing', (event) => {
        let title = pathname + " - " + event.detail.plyr.config.title;

        _mtm.push({
            'event': 'Video',
            'video_status': 'Playing',
            'video_title': title
        });
    });

    players[video.id].on('play', (event) => {
        let title = pathname + " - " + event.detail.plyr.config.title;

        _mtm.push({
            'event': 'Video',
            'video_status': 'Play',
            'video_title': title
        });
    });

    players[video.id].on('pause', (event) => {
        let title = pathname + " - " + event.detail.plyr.config.title;

        _mtm.push({
            'event': 'Video',
            'video_status': 'Pause',
            'video_title': title
        });
    });

    players[video.id].on('ended', (event) => {
        let title = pathname + " - " + event.detail.plyr.config.title;

        _mtm.push({
            'event': 'Video',
            'video_status': 'Ended',
            'video_title': title
        });
    });

});