$( function(){

    // Charactere counter
    $.each($(".char-counter"), function () {

        var $char = $(this);
        var $field = $char.parent().find("textarea, input");
        var $maxlength = $field.attr("data-maxlength");
        var $recommendedlength = $field.attr("data-recommendedlength");
        var $charValue = $char.text();

        if ($field.hasClass("Richtext")) {
            var $iframe = $field.prev('iframe');
            var $currentLength = $iframe.contents().find("body").text().replace(/\s+/g, " ").replace(/[\u2018\u2019\u201A]/g,"\'").replace(/[\u201C\u201D\u201E]/g, "\"").replace(/\u2026/g, "…").replace(/[\u2013\u2014]/g, "-").replace(/\u02C6/g, "^").replace(/\u2039/g, "<").replace(/\u203A/g, ">").replace(/[\u02DC\u00A0]/g, " ").trim().length;

            if($maxlength != undefined) {
                $char.text($charValue.replace("{0}", $maxlength - $currentLength));

            }else {
                $char.text($charValue.replace("{0}", $recommendedlength - $currentLength));
            }

            $iframe.contents().find("body").on('input', function (e) {
                var $currentLength = $(this).text().replace(/\s+/g, " ").replace(/[\u2018\u2019\u201A]/g,"\'").replace(/[\u201C\u201D\u201E]/g, "\"").replace(/\u2026/g, "…").replace(/[\u2013\u2014]/g, "-").replace(/\u02C6/g, "^").replace(/\u2039/g, "<").replace(/\u203A/g, ">").replace(/[\u02DC\u00A0]/g, " ").length;

                if($maxlength != undefined) {
                    if( $currentLength <= $maxlength ){
                        $char.text($charValue.replace("{0}", $maxlength - $currentLength));
                    }
                }else {
                    if( $currentLength <= $recommendedlength ){
                        $char.text($charValue.replace("{0}", $recommendedlength - $currentLength));
                    }else {
                        $char.text($charValue.replace("{0}", "0"));
                    }
                }
            });
        } else {
            var $currentLength = $field.val().trim().length;

            if($maxlength != undefined) {
                $char.text($charValue.replace("{0}", $maxlength - $currentLength));

            }else {
                $char.text($charValue.replace("{0}", $recommendedlength - $currentLength));
            }

            $field.on("input", function(){
                var $currentLength = $field.val().length;

                if($maxlength != undefined) {
                    if( $currentLength <= $maxlength ){
                        $char.text($charValue.replace("{0}", $maxlength - $currentLength));
                    }
                }else {
                    if( $currentLength <= $recommendedlength ){
                        $char.text($charValue.replace("{0}", $recommendedlength - $currentLength));
                    }else {
                        $char.text($charValue.replace("{0}", "0"));
                    }
                }
            });
        }

    });


    // Delete media input file
    $(document).on("click", ".media-button-delete", function (e) {
        e.preventDefault();
        var $this = $(this);

        if (confirm($this.data("confirm-request"))) {
            $.ajax({
                url: $this.data("service"),
                type: "GET",
                traditional: true,
                async: false,
                cache: false,
                dataType: "json",
                contentType: "application/json; charset=utf-8",
                success: function () {
                    $this.parents("li").remove();
                },
                failure: function () {
                    alert("Error to execute action. Please retry.")
                }
            })
        }

    });

    // Scroll to message after submit
    if ($(".intuitive-forms .alert").length > 0) {
        $('html, body').animate({
            scrollTop: $(".intuitive-forms .alert").offset().top
        }, 1000);
    }

})
